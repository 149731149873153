import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Products from './components/pages/Products';
import Clients from './components/pages/Clients';
import FAQs from './components/pages/FAQs';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Footer from './components/Footer';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />"
          <Route path='/about' element={<About />} />"
          <Route path='/services' element={<Services />} />"
          <Route path='/products' element={<Products />} />"
          <Route path='/clients' element={<Clients />} />"
          <Route path='/faqs' element={<FAQs />} />"
          <Route path='/contact' element={<Contact />} />"
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
