import React from 'react';
import '../../App.css';

function FAQs() {
  return(
     <><h1 className='faqs'>FAQs</h1><div id="faq" class="faq-body">
      <div class="faq-header">
        <h3 class="faq-title">FAQ's</h3>
        <div class="seperator"></div>
      </div>
      <div class="faq-list">
        <div>
          <details open>
            <summary title="Who are we?">Who are we ?</summary>
            <p class="faq-content">We are a provider of Pre-Engineered Building (PEB) and Pre-Fabricated Building (PFB) solutions. Our mission is to deliver the best service within our clients' budget and schedule. Our motto is Quality, Timely Delivery, and Customer Satisfaction.
</p>
          </details>
        </div>
        <div>
          <details>
            <summary title="What can we do for you?">What can we do for you ?</summary>
            <p class="faq-content">We can plan your project,
estimate your project,
prepare drawings,
submit quotations,
purchase all the materials and
execute fabrication & erection
</p>
          </details>
        </div>
        <div>
          <details>
            <summary title="What are the advantages of PEB buildings ?">What are the advantages of PEB buildings ?</summary>
            <p class="faq-content"> - Widely steel is used in buildings construction in new era.</p>
            <p class="faq-content"> - It's economical and faster construction than RCC construction.</p>
            <p class="faq-content"> - It is designed and fabricated at factory and easy installation at site due to complete bolting system </p>
            <p class="faq-content"> - It has good resale value than RCC buildings </p>
            <p class="faq-content"> - It is widely used in Industrial buildings, warehouses, metros roof structure, etc.</p>
          </details>
        </div>
        <div>
          <details>
            <summary title="How much will this cost you?">How much will this cost you ?</summary>
            <p class="faq-content">As low as possible, PEB is designed for light structure and high load bearing capacity.
The Cost Varies on type of system you are looking for and products used in that system.</p>
          </details>
        </div>
        <div>
          <details>
            <summary title="What are the typical problems you should look out for?">What are the typical problems you should look out for ?
</summary>
            <p class="faq-content">Your problems are ours, leave them to us to handle.</p>
          </details>
        </div>
        <div>
          <details>
            <summary title="Why would you choose us over others?">Why would you choose us over others ?</summary>
            <p class="faq-content">Not because we are creative, young, and dynamic, but because we are good at what we do, and trustworthy.
</p>
          </details>
        </div>
        <div>
          <details>
            <summary title="What surprises can you expect?">What surprises can you expect ?</summary>
            <p class="faq-content">Riots in the city hold up work!
The plant making grills can burn down!
The Company may go on strike!
But at the end we know we are responsible and NO EXCUSES.</p>
          </details>
        </div>
      </div>
    </div></> 
  ); 
}

export default FAQs;