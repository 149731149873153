import React from 'react';
import './Footer.css';
/*import { Button } from './Button';
import { Link } from 'react-router-dom';*/

function Footer() {
  return (
   /* <div className='footer-container'>
      <footer class="footer-section">
        <div class="container">
            <div class="footer-cta pt-5 pb-5">
                <div class="row">
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fas fa-map-marker-alt"></i>
                            <div class="cta-text">
                                <h4>Find us</h4>
                                <span>1010 Avenue, sw 54321, chandigarh</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fas fa-phone"></i>
                            <div class="cta-text">
                                <h4>Call us</h4>
                                <span>9876543210 0</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="far fa-envelope-open"></i>
                            <div class="cta-text">
                                <h4>Mail us</h4>
                                <span>mail@info.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </footer>
      </div>*/

      <footer class="footer">
  <div class="footer-left col-md-4 col-sm-6">
    <p class="aboutu">
      <span> About the company </span> 
      AMB provides solutions for all types of Pre-Engineered and Pre-fabricated Buildings. No matter what you are looking for, we customize designs to exactly meet your needs. Our customers appreciate working with us because we provide quality work within the agreed-upon time frame. AMB believes in utilizing sustainable technologies through quality fabrication, project management, and engineering services. Reach out today to get an initial quote and start working together.​
    </p>
  </div>
  <div class="footer-center col-md-4 col-sm-6">
    <div>
      <i class="fa fa-map-marker"></i>
      <p><span> 216, Spot 18, Rahatani Road, Pimple Saudagar </span>Pune,  Maharashtra-411017</p>
    </div>
    <div>
      <i class="fa fa-phone"></i>
      <p><a href="tel: 9561744417">(+91) 95617 44417</a></p>
    </div>
    <div>
      <i class="fa fa-envelope"></i>
      <p><a href="mailto: anchalaa.jha@gmail.com"> sales@ambinfra.com</a></p>
    </div>
  </div>
  <div class="footer-right col-md-4 col-sm-6">
    <h2> AMB <span> INFRASTRUCTURE </span></h2>
    <p class="menu">
      <a href="/"> Home</a> |
      <a href="/about"> About</a> |
      <a href="/services"> Services</a> |
      <a href="/products"> Products</a> |
      <a href="/clients"> Clients</a> |
      <a href="/faqs"> FAQs</a> |
      <a href="/contact"> Contact</a>
    </p>
    <p class="name"> ambinfra &copy; 2023</p>
  </div>
</footer>
  );
}

export default Footer;

