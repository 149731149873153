import React from 'react';
import '../../App.css';

export default function Services() {
  return (
  <><h1 className='services'>SERVICES</h1>
 <section class="section-services">
 <div class="container">
      <div class="row justify-content-center text-center">
        <div class="col-md-10 col-lg-8">
          <div class="header-section">
            <h2 class="title">Fabrication & Erection</h2>
            <p class="description">Based on our deep commitment to safety, the quality of our service and workmanship, and our professionalism for every client, we can take your idea from concept to turnkey completion.. 
                                   Whether it's one-of-a-kind or mass produced, we provide: </p>
            .<p class="description">1. PEB Design concept</p>
            <p class="description">2. Design to fabrication facility</p>
            <p class="description">3. Fabrication, sand blasting, painting and erection </p>
            <p class="description">4. Site installation of fabricated steel as per requirement of project</p>
            <p class="description">5. In-house fabrication and construction facilities</p>
            <p class="description">6. Fabrication facilities provided at site as per customer requirement</p>
            <p class="description">7. Civil construction for PEB foundation, flooring, internal roads and boundary walls</p>
          </div>

          <div class="header-section">
          <h2 class="title">STAGES OF SERVICES PROVIDED</h2>
          </div>

			<div class="row">		
				<div class="col-md-6 col-lg-4">
					<div class="single-service">
						<div class="content">
            <span class="icon">
								<i class="fab fa-battle-net"></i>
							</span>
							<h3 class="title">Stage I: Pre-Design Stages::</h3>
							<p class="description">1. Take details of buildings as per Architect's/Owner's proposal and prepare report with estimated Cost for PEB/PFB.</p>
              <p class="description">2. Visit and inspect site if required.</p>
              <p class="description">3. On Approval of preliminary report, prepare preliminary layout</p>
						</div>
						<span class="circle-before"></span>
					</div>
				</div>
				
				<div class="col-md-6 col-lg-4">
					<div class="single-service">
						<div class="content">
							<span class="icon">
								<i class="fab fa-asymmetrik"></i>
							</span>
							<h3 class="title">Stage II: Preliminary Design::</h3>
							<p class="description">1. Preparation of the layout, drawings and details in co-ordination with Architectural & Structural Drawings.</p>
              <p class="description">2. Detailed layouts and design for optimum cost.</p>
						
						</div>
						<span class="circle-before"></span>
					</div>
				</div>
			
				<div class="col-md-6 col-lg-4">
					<div class="single-service">
						<div class="content">
							<span class="icon">
								<i class="fab fa-artstation"></i>
							</span>
							<h3 class="title">Stage III: Co-ordination with concerned agencies::</h3>
							<p class="description">1. Progress-check meetings on a regular basis at site, with all other agencies working at site (In Consultation with Architect/Owners).</p>
              <p class="description">2. Inspection/Supervision of technical requirement fulfillment of works.</p>
							
						</div>
						<span class="circle-before"></span>
					</div>
				</div>
			</div>
    </div>
    </div>
    </div>
	</section>
  </>
);
}