import React from 'react';
import '../../App.css';



function Contact(){
  return (
    <>
    <h1 className='contact'>CONTACT US</h1>
    <section class="footer_get_touch_outer">
    <div class="cont">
      <div class="footer_get_touch_inner grid-70-30">
        <div class="colmun-70 get_form">
          <div class="get_form_inner">
            <div class="get_form_inner_text">
              <h3>Get In Touch</h3>
            </div>
            <form action="https://formsubmit.co/sales@ambinfra.com" method="POST">
              <div class="grid-50-50">
                <input type="text" placeholder="First Name" required/>
                <input type="text" placeholder="Last Name" required/>
                <input type="email" placeholder="Email" required/>
                <input type="tel" placeholder="Phone Number" required/>
              </div>
              <div class="grid-full">
                <textarea placeholder="Write your message" cols="30" rows="10"></textarea>
                <input type="submit" value="Submit"/>
              </div>
            </form>
          </div>
        </div>
        <div class="colmun-30 get_say_form">
          <h5>Say Hi!</h5>
          <ul class="get_say_info_sec">
            <li>
              <i class="fa fa-envelope"></i>
              <a href="mailto: sales@ambinfra.com ">sales@ambinfra.com</a>
            </li>
            <li>
              <i class="fa fa-phone"></i>
              <a href="tel: 9561744417">+91 95617 44417/ 7588021798</a>
            </li>
            <li>
              <i class="fa fa-map-marker"></i>
              <a href="map: https://www.google.co.in/maps/place/SPOT18/@18.5936348,73.7860344,15z/data=!4m5!3m4!1s0x0:0x5151d39167e0fdc5!8m2!3d18.5936348!4d73.7860344">216, Spot 18, Rahatani Road, Pimple Saudagar,Pune-411017</a> 
            </li>
            </ul> 
            <div class="contact-logo">
              <img src='../images/amblogo-modified.png' alt='Logo'/>
            </div>              
        </div>        
      </div>
    </div>
  </section>




    </>
    );
}

export default Contact;