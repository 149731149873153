import React from 'react';
import '../../App.css';



export default function Clients() {
  return (
  
  <><h1 className='clients'>CLIENTS</h1>
  <div class="contain">
      <h1>Clients who have trusted us</h1>
      <div class="rows">
        <div class="serv">
          <i class="fas fa-laptop-code"></i>
          <h2>Raigad Steel and Roll Forms Pvt Ltd</h2>
          
        </div>
        <div class="serv">
          <i class="fas fa-chart-line"></i>
          <h2>Element Exim Industries India Pvt Ltd</h2>
          
        </div>
        <div class="serv">
          <i class="fab fa-sketch"></i>
          <h2>Maas Fabricators and Constructions</h2>
          
        </div>
        <div class="serv">
          <i class="fas fa-database"></i>
          <h2>All Services Global Pvt Ltd</h2>
          
        </div>
        <div class="serv">
          <i class="fas fa-mobile-alt"></i>
          <h2>D S Enterprises</h2>
          
        </div>
        <div class="serv">
          <i class="fas fa-mobile-alt"></i>
          <h2>ABR Steel</h2>
          
        </div>
      </div>
    </div>
  </>
    );
}