import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Welcome</h1>
      <p>Welcome to AMB Infrastructure! AMB Infrastructure is a fast growing company which provides solutions for all types of Pre-Engineered and Pre-fabricated Buildings. From customized design & engineering to fabrication & installation, we handle every aspect of the process to ensure that our clients receive the best possible results. Our customers appreciate working with us because we provide quality work within the agreed-upon time frame. AMB Infrastructure believes in utilizing sustainable technologies through quality fabrication, project management, and engineering services. We are a team of highly skilled professionals dedicated to providing our clients with top-quality fabrication services for a wide range of industries. 
         Feel free to contact us with any questions or to request a quote. </p>
      <h1>Reach out today to get an initial quote and start working together !</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='../images/put2.jpg'
              text='Explore and know more about us'
              label='About Us'
              path='/about'
            />
            <CardItem
              src='../images/put16.jpg'
              text='Take a look at all the services we provide'
              label='Services'
              path='/services'
            />
            <CardItem
              src='../images/put7.jpg'
              text='Find out all about the products we make and provide'
              label='Products'
              path='/products'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='../images/put6.jpg'
              text='Take a look at all our clients who have chosen to believe in us'
              label='Clients'
              path='/clients'
            />
            <CardItem
              src='../images/put1.jpg'
              text='Have any questions ? Have them answered'
              label='FAQs'
              path='/faqs'
            />
            <CardItem
              src='../images/put4.jpg'
              text='Know our details and contact us'
              label='Contact Us'
              path='/contact'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;