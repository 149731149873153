import React from 'react';
import '../../App.css';

function About() {
  return (
  <>
  <h1 className='about'>ABOUT US</h1>
  <section class="about-section">
      <div class="container">
        <div class="row">
          <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
            <div class="inner-column">
              <div class="sec-title">
                <h2>About AMB Infrastructure</h2>
              </div>
              <div class="text">At AMB Infrastructure, we are an emerging fabrication company that specializes in creating custom solutions for a wide range of industries. We take pride in our deep commitment to safety, quality of service and workmanship, and professionalism for every client. Our team of experienced engineers, fabricators and project managers work closely with our clients to understand their unique needs and design solutions that exceed their expectations. From concept to turnkey completion, we are dedicated to delivering the highest quality products and services on time and within budget. We are committed to utilizing sustainable technologies and continuously improving our processes to ensure we meet the ever-changing demands of the industry. Whether you need a one-off prototype or a large-scale production run, we are committed to providing our clients with the best possible experience.</div>
              <div class="text">
              AMB Infrastructure has a dedicated team of professionals to manage its affairs, well supported by a team of engineers and professionals for respective areas to ensure adherence to quality and timely delivery.

AMB Infrastructure is an ancillary organization, established by people who have been in the field for many years of association in steel fabrication and erection. The company has the infrastructure to provide light, medium, and heavy structure fabrication for PEB and PFB of any size.
              </div>

              <div class="sec-title">
                <h2>Our Future Goal</h2>
              </div>
              <div class="text">
              At AMB Infrastructure, our goal is to become a leader in the fabrication industry by providing high-quality products and services to our clients. We strive to expand our capabilities and improve our processes to meet the ever-changing demands of the industry. We are constantly looking for new ways to innovate and improve, and we are committed to staying at the forefront of the industry. We are also committed to sustainability, and we will continue to invest in technologies that reduce our environmental impact.
              </div>
              <div class="text">
              In the future, we plan to expand our reach to new markets and clients, both domestically and internationally. We also aim to deepen our expertise in specific areas of fabrication to become a one-stop solution for our clients. We will continue to invest in our team by providing training and development opportunities to ensure they have the skills and knowledge to meet the challenges of the future. Our ultimate goal is to provide the best possible experience for our clients and to build long-lasting relationships based on trust and mutual respect.
              </div>
              <div class="text">
              Our reputation is being built on satisfied customers.
              </div>
            </div>
            </div>
          </div>
          </div>
          </section>
          </>
        );
}

export default About;